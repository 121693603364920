import React, {useEffect, useState} from 'react'
import {IngredientSearchEntry, MedicationSearchEntry, OptionItem, RequestedPrescriptionDto} from '../../../utils/types'
import {sharedStyles} from '../../../style/shared_styles'
import {ActionButtons, CustomSelect, Input, LiveSearch, TextAreaInput, TitleText} from '../../common'
import {i18n} from '../../../i18n'
import {Color} from '../../../style/custom/colors'
import {SearchTypes} from '../../../utils/enums'
import {buildSelectOptionsObject, convertFloatNumber} from '../../../utils/helper'
import {useForm, useLoading} from '../../../utils/hooks'
import {EditMedKeys, requiredMedMandatoryFields} from './utils/keys'
import {observer} from 'mobx-react'
import {useStores} from '../../../store'
import {MedicationUrls} from '../../../store/utils/types'
import MultiLiveSearch from '../../common/MultiLiveSearch'
import {getIngredientNotes} from '../../../utils/medication_helper'

/**
 * Component for editing the requested medication in a modal.
 */
const EditRequestedMed: React.FC<EditRequestedMedProps> = observer((props: EditRequestedMedProps) => {
    const [formState, extractProps, onValidateInputs, inputChangeHandler] = useForm(props.requestedMed, requiredMedMandatoryFields)
    const [editLoading, setEditIsLoading] = useLoading(false)
    const [deleteLoading, setDeleteLoading] = useLoading(false)

    const [disabledData, setDisabledData] = useState(false)

    const {therapyStore, userStore} = useStores()

    const unitsOption: OptionItem[] = buildSelectOptionsObject(userStore.getUnitMeasure)

    const onSubmit = async () => {
        const isValid = onValidateInputs()
        let data = formState.values as RequestedPrescriptionDto
        if (!isValid || !data) {
            return
        }

        data = {
            ...data,
            singleDose: convertFloatNumber(data.singleDose) || 0,
            productName: data.productName,
            pzn: data.pzn
        }
        setEditIsLoading(true)
        const isSuccess = await therapyStore.createUpdateMedication(data, MedicationUrls.REQUIRED)
        setEditIsLoading(false)

        if (isSuccess) {
            props.onCloseModal?.()
        }
    }

    const onDelete = async () => {
        setDeleteLoading(true)
        await therapyStore.deleteMedication(props.requestedMed!.id, MedicationUrls.REQUIRED)
        props.onCloseModal?.()
        setDeleteLoading(false)
    }

    const onMedicationChange = (val: MedicationSearchEntry, error?: string) => {
        setDisabledData(!!val.unit)
        inputChangeHandler(val.name, EditMedKeys.productName, error)
        inputChangeHandler(val.pzn, EditMedKeys.pzn, error)
        inputChangeHandler(val?.unit || unitsOption[0].value, EditMedKeys.unit, error)
        inputChangeHandler(val?.dosage, EditMedKeys.singleDose, error)
        onIngredientsChange(val.ingredients, '', false)
    }

    const onIngredientsChange = (values: IngredientSearchEntry[], error?: string, isPrefill?: boolean) => {
        inputChangeHandler(values, EditMedKeys.ingredients, error)
        if (!isPrefill) {
            // this is necessary so that on prefill the previous note is not overwritten
            inputChangeHandler(getIngredientNotes(values), EditMedKeys.note, error)
        }
    }

    const medication: MedicationSearchEntry | undefined = formState.values.pzn
        ? {
              id: formState.values.pzn,
              name: formState.values.productName || '',
              dosage: null,
              pzn: formState.values.pzn,
              unit: formState.values.unit,
              ingredients: formState.values.ingredients || [],
              companyName: ''
          }
        : undefined

    useEffect(() => {
        if (!props.requestedMed) {
            inputChangeHandler(unitsOption[0].value, EditMedKeys.unit)
        }
        if (props.requestedMed?.unit) {
            setDisabledData(true)
        }
    }, [])
    return (
        <div>
            <TitleText text={i18n.t('editMed.medicationTitle')} />
            <MultiLiveSearch
                searchType={SearchTypes.ingredient}
                searchThreshold={2}
                mandatory={requiredMedMandatoryFields.includes(EditMedKeys.ingredients)}
                label={i18n.t('editMed.ingredientLabel')}
                placeholder={i18n.t('editMed.ingredientPlaceholder')}
                defaultValues={formState.values.ingredients}
                onValueChanged={onIngredientsChange}
                disabled={!!medication}
                error={formState.errors[EditMedKeys.ingredients]}
            />
            <div style={{display: 'flex'}}>
                <div style={sharedStyles.leftColumn}>
                    <Input
                        label={i18n.t('editMed.baseDose.title')}
                        extraInfo={i18n.t('editMed.baseDose.extra')}
                        {...extractProps(EditMedKeys.baseDose)}
                    />
                    <Input label={i18n.t('editMed.singleDose')} min={0} type={'number'} {...extractProps(EditMedKeys.singleDose)} />
                </div>
                <div style={sharedStyles.rightColumn}>
                    <Input
                        label={i18n.t('editMed.bodySurface')}
                        disabled={true}
                        onValueChanged={() => {
                            /**/
                        }}
                        value={therapyStore.therapyComplete?.generalInfo.bodySurface?.toLocaleString(i18n.locale)}
                    />
                    <CustomSelect label={i18n.t('editMed.unit')} disabled={disabledData} items={unitsOption} {...extractProps(EditMedKeys.unit)} />
                </div>
            </div>
            <div style={{background: Color.neutral5, padding: 10}}>
                <LiveSearch
                    searchType={SearchTypes.product}
                    label={i18n.t('editMed.medicationLabel')}
                    defaultValue={medication}
                    error={formState.errors[EditMedKeys.medication]}
                    onValueChanged={onMedicationChange}
                    searchOptions={{filter: formState.values.ingredients?.map(i => i.id) || []}}
                    searchThreshold={4}
                    skipInitialize={true}
                />
                <Input label={i18n.t('editMed.aut')} type={'checkbox'} {...extractProps(EditMedKeys.autIdem)} />
            </div>

            <div>
                <TitleText text={i18n.t('editMed.additionalTitle')} />
                <TextAreaInput label={i18n.t('editMed.note')} {...extractProps(EditMedKeys.note)} />
            </div>

            <ActionButtons
                onCancel={props.onCloseModal}
                renderDelete={props.requestedMed && therapyStore.canDeleteMedication}
                loadingSave={editLoading}
                loadingDelete={deleteLoading}
                onDelete={onDelete}
                onSave={onSubmit}
            />
        </div>
    )
})

type EditRequestedMedProps = {
    requestedMed?: RequestedPrescriptionDto
    onCloseModal?: () => void
}

export default EditRequestedMed
