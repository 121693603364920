import React from 'react'
import {InfoCircleOutlined, StarFilled} from '@ant-design/icons'
import {Color} from '../../style/custom/colors'
import {Tooltip} from 'antd'
import {styles} from '../patient/styles/TherapySection.style'

/**
 * The custom component used for rendering the label value attached to an input.
 */
const InputLabel: React.FC<InputLabelProps> = (props: InputLabelProps) => {
    return (
        <div style={{display: 'flex', alignItems: 'center'}}>
            {!!props.mandatory && <StarFilled style={{color: Color.warningStrong, fontSize: 6, marginRight: 3}} />}

            <span style={{fontSize: 13, color: Color.neutral2}}>{props.label}</span>

            {props.extraInfo && (
                <Tooltip title={props.extraInfo}>
                    <InfoCircleOutlined style={{...styles.icon, marginLeft: 5}} />
                </Tooltip>
            )}
        </div>
    )
}

export interface InputLabelProps {
    label?: string
    mandatory?: boolean
    extraInfo?: string
    skipInitialize?: boolean // linked to default value initialization; put it here because all Input components will extends this
}

export default InputLabel
