import React from 'react'
import {Color} from "../../../../style/custom/colors";
import {observer} from "mobx-react";

/**
 * Generic component for displaying an entry/field.
 */
const DetailsEntry: React.FC<DetailsEntryProps> = observer((props) => {
    return (
        <div style={{display: 'flex', flexDirection: 'column'}}>
            <span style={{color: Color.neutral2, fontSize: 12}}>{props.title}</span>
            <span style={{color: Color.black}}>{props.value || '-'}</span>
        </div>
    )
})

type DetailsEntryProps = {
    title: string,
    value: string | number | undefined,
}

export default DetailsEntry
