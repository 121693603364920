import React from 'react'
import {sharedStyles} from "../../../../style/shared_styles";
import {TitleText} from "../../../common";
import {i18n} from '../../../../i18n'
import {Spin} from "antd";
import {SideEffectType, VitalDataType, VitalParameterDefinition} from "../../../../utils/types";
import {partition} from "../../../../utils/observation_helper";
import {Color} from "../../../../style/custom/colors";
import SideEffectEntry from "../observationsTab/SideEffectEntry";
import VitalDataEntry from "../observationsTab/VitalDataEntry";
import {observationSorter} from "../../utils/observation_helper";

/**
 * Component displaying side effects and vital data used in the verification step.
 */
const ObservationsDisplay: React.FC<ObservationsDisplayProps> = (props) => {
    const sortedEffects = props.sideEffects?.sort(observationSorter(false)) || []
    const sortedVitals = props.vitalData?.sort(observationSorter(false)) || []
    const [systemEffects, effects] = partition(sortedEffects, se => se.isSystem)
    const [systemVitals, vitals] = partition(sortedVitals, vd => vd.isSystem)
    return (
        <div style={{display: 'flex'}}>
            {/* Side Effects */}
            <div style={sharedStyles.leftColumn}>
                <div style={{...sharedStyles.inlineContainer, marginBottom: '2%'}}>
                    <TitleText text={i18n.t('therapy.verify.sideEffects.title')}/>
                </div>
                {props.loading ? <Spin/> : (sortedEffects.length === 0 ? <span>{i18n.t('common.errors.noData')}</span> : <>
                    {systemEffects.length > 0 &&
                    <div style={{flex: 1, ...sharedStyles.lightSectionWrapper}}>
                        <TitleText text={i18n.t('therapy.verify.system')} size={16} color={Color.black}/>
                        {systemEffects.map((se: SideEffectType) => (
                            <SideEffectEntry sideEffect={se} disabled={props.disabled} key={se.id}
                                             onSwitchChange={() => props.onSwitchChange?.('sideEffects', se.id)}
                                             onValueChanged={value => props.onSideEffectChange?.(se.id, value)}/>
                        ))}
                    </div>}
                    {effects.length > 0 &&
                    <div style={{flex: 1, marginTop: '2%', ...sharedStyles.lightSectionWrapper}}>
                        <TitleText text={i18n.t('therapy.verify.other')} size={16} color={Color.black}/>
                        {effects.map((se: SideEffectType) => (
                            <SideEffectEntry sideEffect={se} disabled={props.disabled} key={se.id}
                                             onSwitchChange={() => props.onSwitchChange?.('sideEffects', se.id)}
                                             onValueChanged={value => props.onSideEffectChange?.(se.id, value)}/>
                        ))}
                    </div>}
                </>)}
            </div>
            {/* Vital Data */}
            <div style={{...sharedStyles.rightColumn, marginLeft: '2%'}}>
                <div style={{...sharedStyles.inlineContainer, marginBottom: '2%'}}>
                    <TitleText text={i18n.t('therapy.verify.vitalData.title')}/>
                </div>
                {props.loading ? <Spin/> : (sortedVitals.length === 0 ? <span>{i18n.t('common.errors.noData')}</span> : <>
                    {systemVitals.length > 0 &&
                    <div style={{...sharedStyles.lightSectionWrapper}}>
                        <TitleText text={i18n.t('therapy.verify.system')} size={16} color={Color.black}/>
                        {systemVitals.map((vd: VitalDataType) => (
                            <VitalDataEntry vitalData={vd} disabled={props.disabled} key={vd.id}
                                            onSwitchChange={() => props.onSwitchChange?.('vitalData', vd.id)}
                                            onValueChanged={(param, value, type) => props.onVitalDataChange?.(vd.id, param, value, type)}/>
                        ))}
                    </div>}
                    {vitals.length > 0 &&
                    <div style={{flex: 1, marginTop: '2%', ...sharedStyles.lightSectionWrapper}}>
                        <TitleText text={i18n.t('therapy.verify.other')} size={16} color={Color.black}/>
                        {vitals.map((vd: VitalDataType) => (
                            <VitalDataEntry vitalData={vd} disabled={props.disabled} key={vd.id}
                                            onSwitchChange={() => props.onSwitchChange?.('vitalData', vd.id)}
                                            onValueChanged={(param, value, type) => props.onVitalDataChange?.(vd.id, param, value, type)}/>
                        ))}
                    </div>}
                </>)}
            </div>
        </div>
    )
}

type ObservationsDisplayProps = {
    sideEffects: SideEffectType[] | null,
    vitalData: VitalDataType[] | null
    disabled?: boolean
    loading?: boolean
    onSwitchChange?: (type: 'sideEffects' | 'vitalData', obsId: string) => void
    onSideEffectChange?: (sideEffectId: string, val: number) => void
    onVitalDataChange?: (vitalDataId: string, param: VitalParameterDefinition, val: string, type: 'minThreshold' | 'maxThreshold') => void
}

export default ObservationsDisplay
