import {LocalStyleSheet} from '../../../utils/types'
import {sharedStyles} from '../../../style/shared_styles'
import {Color} from '../../../style/custom/colors'

export const styles: LocalStyleSheet = {
    wrapper: {
        ...sharedStyles.container,
        justifyContent: 'space-between'
    },

    statusWrapper: {
        display: 'flex',
        flexDirection: 'row'
    },

    buttonWrapper: {
        display: 'flex',
        alignItems: 'center'
    },

    warningMessageWrapper: {
        display: 'flex',
        flex: 1,
        justifyContent: 'center',
        backgroundColor: Color.warningStrong,
        borderRadius: 5
    },

    warningMessage: {
        margin: 5,
        fontSize: 18,
        color: Color.black
    }
}
