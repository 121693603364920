import React, {ReactNode, useState} from 'react'
import {Color} from '../../../../style/custom/colors'
import {i18n} from '../../../../i18n'
import {EditFilled, InfoCircleOutlined} from '@ant-design/icons'
import {styles} from '../../styles/TherapySection.style'
import {Tooltip} from 'antd'
import {entryStyles} from '../../styles/EditDetailsEntry.style'

/**
 * Generic component for editing an entry/field.
 */
const EditDetailsEntry: React.FC<EditDetailsEntryProps> = (props: EditDetailsEntryProps) => {
    const [iconColor, setIconColor] = useState(Color.neutral4)
    const isReactElement = React.isValidElement(props.value)

    const wrapperStyle = props.removeTopPadding ? {...entryStyles.wrapper, paddingTop: 0} : entryStyles.wrapper

    return (
        <div style={wrapperStyle}>
            <div>
                <span style={entryStyles.title}>{props.title}</span>
                {props.extraInfo && (
                    <Tooltip title={props.extraInfo}>
                        <InfoCircleOutlined style={{...styles.icon, marginLeft: 5}} />
                    </Tooltip>
                )}
            </div>
            <div style={entryStyles.valueWrapper}>
                {!isReactElement ? <span style={entryStyles.value}>{props.value || '-'}</span> : props.value}
                {props.onEdit && !props.hasOperationsErrors && (
                    <Tooltip title={i18n.t('button.edit')}>
                        <EditFilled
                            style={{...styles.icon, color: iconColor}}
                            onClick={props.onEdit}
                            onMouseOver={() => setIconColor(Color.neutral2)}
                            onMouseOut={() => setIconColor(Color.neutral4)}
                        />
                    </Tooltip>
                )}
            </div>
        </div>
    )
}

type EditDetailsEntryProps = {
    title: string
    value: string | number | ReactNode | undefined
    onEdit?: () => void
    extraInfo?: string
    removeTopPadding?: boolean
    hasOperationsErrors?: boolean | undefined | null
}

export default EditDetailsEntry
