
export enum Routes {
    HOME = '/',
    ABOUT = '/about',
    NEW_PATIENT = '/new-patient',
    PATIENT = '/patient',
    MY_PROFILE = '/profile',
    IMPRESSUM = '/impressum'

}
