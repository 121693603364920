import {LocalStyleSheet} from '../../../utils/types'
import {sharedStyles} from '../../../style/shared_styles'
import {Color} from '../../../style/custom/colors'

export const styles: LocalStyleSheet = {
    pageWrapper: {
        display: 'flex',
        flexDirection: 'column'
    },
    sideBySide: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginTop: '1%',
        marginBottom: '1%'
    },

    operationErrorContainer: {
        ...sharedStyles.container,
        marginTop: '1%',
        background: Color.warningStrong,
        display: 'block'
    },

    operationErrorMessage: {
        fontSize: 18,
        marginBottom: 0,
        fontWeight: 'bold'
    }
}
