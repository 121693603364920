import React, {useEffect, useState} from 'react'
import {i18n} from '../../../i18n'
import {ActionButtons, CustomModal, CustomSelect, Input, TextAreaInput, TitleText} from '../../common'
import {sharedStyles} from '../../../style/shared_styles'
import {
    IngredientSearchEntry,
    MedicationSearchEntry,
    OptionItem,
    TherapyMedicationDto,
    TherapyPrescriptionDto
} from '../../../utils/types'
import {buildEnumOptions, buildSelectOptionsObject, convertFloatNumber} from '../../../utils/helper'
import {IntakeType, SearchTypes, TherapyMedicationGroup} from '../../../utils/enums'
import {useForm, useLoading} from '../../../utils/hooks'
import {ButtonStyle} from '../../../style/button'
import {Button} from 'antd'
import {getIngredientNotes} from '../../../utils/medication_helper'
import BoxDiagram from '../components/subcomponents/BoxDiagram'
import {useTherapyDetails} from '../../common/hooks/useTherapyDetails'
import VSpace from '../../common/VSpace'
import TimeDoseTable from '../components/subcomponents/TimeDoseTable'
import {EditMedKeys, therapyMedMandatoryFields} from './utils/keys'
import {observer} from 'mobx-react'
import {useStores} from '../../../store'
import {MedicationUrls} from '../../../store/utils/types'
import MultiLiveSearch from '../../common/MultiLiveSearch'
import {Color} from '../../../style/custom/colors'
import {updateDosageLinks} from '../utils/observation_helper'

/**
 * Component responsible with editing the therapy medication in a modal.
 */
const EditTherapyMedication: React.FC<EditTherapyMedicationProps> = observer(props => {
    const [formState, extractProps, onValidateInputs, inputChangeHandler] = useForm(props.med, therapyMedMandatoryFields)
    const {dailyDose, originalDose, doseModification, days, unit, therapyMedicationList, ingredients} = formState.values
    // disable flag for data which is related to selected medication
    const [disabledData, setDisabledData] = useState(false)
    const [disabledUnitSelection, setDisabledUnitSelection] = useState(false)
    // general error messages displayed in modal
    const [errorMessages, setErrorMessages] = useState<string[]>([])

    const [submitPressed, setSubmitPressed] = useState(false)
    const [deleteLoading, setDeleteLoading] = useLoading(false)

    const {duration} = useTherapyDetails()
    const {therapyStore, userStore} = useStores()

    useEffect(() => {
        if (!props.med) {
            inputChangeHandler(groupOptions[0]?.value, EditMedKeys.group)
            inputChangeHandler(typeOptions[0]?.value, EditMedKeys.type)
        }
        const noOfTherapyMeds = props.med?.therapyMedicationList?.length
        if (props.med && noOfTherapyMeds && noOfTherapyMeds > 0) {
            setDisabledData(true)
        }
    }, [])

    /**
     * compute one field from other two
     * @param event
     */
    const onBlur = (event: any) => {
        const data = updateDosageLinks(event.target.id, dailyDose, originalDose, doseModification)
        if (data) {
            inputChangeHandler(data[0], data[1])
        }
    }

    const setDays = (value: string, error?: string) => {
        if (error) {
            inputChangeHandler(value, EditMedKeys.days, error)
            return
        }
        inputChangeHandler(value, EditMedKeys.days)
    }

    // Use selected medication to prefill ingredients, unit, and note
    const onMedicationSelect = (selectedMedication: MedicationSearchEntry | null) => {
        if (!selectedMedication) {
            // reset in case medication is deleted
            setDisabledData(false)
            onIngredientsChange([])
            return
        }
        const selectedIngredients = selectedMedication.ingredients

        setDisabledData(true)
        onIngredientsChange(selectedIngredients, '', false)
    }

    const onIngredientsChange = (values: IngredientSearchEntry[], error?: string, isPrefill?: boolean) => {
        inputChangeHandler(values, EditMedKeys.ingredients, error)
        if (!isPrefill) {
            // this is necessary so that on prefill the previous note is not overwritten
            inputChangeHandler(getIngredientNotes(values), EditMedKeys.note, error)
        }
    }

    const onSave = () => {
        setSubmitPressed(true)
    }

    const onDelete = async () => {
        setDeleteLoading(true)
        await therapyStore.deleteMedication(props.med!.id, MedicationUrls.THERAPY)
        props.onCancel()
        setDeleteLoading(false)
    }

    const actualSave = async (therapyMedications: TherapyMedicationDto[], errMessages: string[]) => {
        if (!onValidateInputs()) {
            setSubmitPressed(false)
            return
        }
        if (errMessages.length !== 0) {
            setErrorMessages(errMessages)
            setSubmitPressed(false)
            return
        }
        // copy selected  unit to all meds (in case the med did not had unit)
        therapyMedications.forEach(timeDose => {
            timeDose.unit = unit || ''
        })

        inputChangeHandler(therapyMedications, EditMedKeys.timeDoses)
        // do this also because 'inputChangeHandler' is async
        formState.values.therapyMedicationList = therapyMedications
        formState.values.dailyDose = convertFloatNumber(formState.values.dailyDose)
        formState.values.originalDose = convertFloatNumber(formState.values.originalDose)

        await therapyStore.createUpdateMedication(formState.values as TherapyPrescriptionDto, MedicationUrls.THERAPY)
        props.onCancel()
        setSubmitPressed(false)
    }

    // set current unit based on medication selection
    const onChangeUnit = (changedUnit: string | null) => {
        if (changedUnit) {
            inputChangeHandler(changedUnit, EditMedKeys.unit)
        } else if (!formState.values.unit) {
            // if no value is set for unit, set the default one
            inputChangeHandler(unitsOption[0]?.value, EditMedKeys.unit)
        }
        setDisabledUnitSelection(!!changedUnit)
    }

    const groupOptions: OptionItem[] = buildEnumOptions(TherapyMedicationGroup, 'therapyTab.therapyMedication.group')
    const unitsOption: OptionItem[] = buildSelectOptionsObject(userStore.getUnitMeasure)
    const typeOptions: OptionItem[] = buildEnumOptions(IntakeType, 'therapyTab.therapyMedication.type')
    return (
        <div>
            {/* ========= Medication Section ========== */}
            <TitleText text={i18n.t('editMed.medicationTitle')} />
            <div style={{display: 'flex'}}>
                <div style={sharedStyles.leftColumn}>
                    <CustomSelect label={i18n.t('editMed.group')} items={groupOptions} {...extractProps(EditMedKeys.group)} />
                    <Input
                        label={i18n.t('editMed.baseDose.title')}
                        extraInfo={i18n.t('editMed.baseDose.extra')}
                        {...extractProps(EditMedKeys.baseDose)}
                    />
                    <Input
                        label={i18n.t('editMed.dailyDose')}
                        min={0}
                        {...extractProps(EditMedKeys.dailyDose)}
                        pixelAlignTop={true}
                        type={'number'}
                        onBlur={onBlur}
                    />
                    <Input
                        label={i18n.t('editMed.originalDailyDose')}
                        min={0}
                        {...extractProps(EditMedKeys.originalDose)}
                        type={'number'}
                        onBlur={onBlur}
                    />
                </div>
                <div style={{...sharedStyles.rightColumn, justifyContent: 'flex-end'}}>
                    <MultiLiveSearch
                        searchType={SearchTypes.ingredient}
                        searchThreshold={2}
                        mandatory={true}
                        label={i18n.t('editMed.ingredientLabel')}
                        placeholder={i18n.t('editMed.ingredientPlaceholder')}
                        defaultValues={formState.values.ingredients}
                        onValueChanged={onIngredientsChange}
                        disabled={disabledData}
                        error={formState.errors[EditMedKeys.ingredients]}
                    />
                    <Input
                        label={i18n.t('editMed.bodySurface')}
                        disabled={true}
                        value={therapyStore.therapyComplete?.generalInfo.bodySurface?.toLocaleString(i18n.locale)}
                        onValueChanged={() => {
                            /**/
                        }}
                    />
                    <CustomSelect
                        label={i18n.t('editMed.unit')}
                        items={unitsOption}
                        disabled={disabledUnitSelection}
                        {...extractProps(EditMedKeys.unit)}
                    />
                    <Input
                        label={i18n.t('editMed.doseModification')}
                        min={0}
                        type={'number'}
                        {...extractProps(EditMedKeys.doseModification)}
                        onBlur={onBlur}
                    />
                </div>
            </div>
            <Input label={i18n.t('editMed.aut')} type={'checkbox'} {...extractProps(EditMedKeys.autIdem)} />

            {/* =========== Intake Section ============ */}
            <TitleText text={i18n.t('editMed.intakeTitle')} />
            <div style={sharedStyles.inline}>
                <div style={{flex: 1, ...sharedStyles.column}}>
                    <CustomSelect label={i18n.t('editMed.type')} items={typeOptions} {...extractProps(EditMedKeys.type)} />
                    <Input label={i18n.t('editMed.days')} {...extractProps(EditMedKeys.days)} />
                </div>
                <div style={{flex: 2, ...sharedStyles.column, marginLeft: '2%'}}>
                    <BoxDiagram id={'editDiagram'} daysString={days} setDaysString={setDays} />
                    <div style={sharedStyles.inlineContainer}>
                        <Button
                            type={'default'}
                            shape={'round'}
                            style={ButtonStyle.primary}
                            onClick={() => inputChangeHandler(`1-${duration}`, EditMedKeys.days)}>
                            {i18n.t('button.selectAll')}
                        </Button>
                        <Button
                            type={'default'}
                            shape={'round'}
                            style={ButtonStyle.primary}
                            onClick={() => inputChangeHandler(undefined, EditMedKeys.days)}>
                            {i18n.t('button.selectNone')}
                        </Button>
                    </div>
                </div>
            </div>

            <TimeDoseTable
                unit={unit}
                dailyDose={dailyDose}
                therapyMedicationList={therapyMedicationList}
                ingredients={ingredients}
                setMedicationChanged={onMedicationSelect}
                onValidate={actualSave}
                onChangeUnit={onChangeUnit}
                triggerValidate={submitPressed}
            />
            <VSpace size={'3%'} />

            {/* ============ Other Section ============ */}
            <TitleText text={i18n.t('editMed.otherTitle')} />
            <TextAreaInput label={i18n.t('editMed.note')} {...extractProps(EditMedKeys.note)} />

            <ActionButtons
                onCancel={props.onCancel}
                renderDelete={props.med && therapyStore.canDeleteMedication}
                onDelete={onDelete}
                onSave={onSave}
                loadingDelete={deleteLoading}
                loadingSave={submitPressed}
            />

            {/* Error Modal */}
            <CustomModal open={errorMessages.length !== 0} title={i18n.t('editMed.error.title')}>
                <div style={{...sharedStyles.column, alignItems: 'center'}}>
                    <ul>
                        {errorMessages.map(msg => (
                            <li key={msg} style={{color: Color.warningStrong, fontSize: 22}}>
                                {msg}
                            </li>
                        ))}
                    </ul>
                    <Button type={'default'} shape={'round'} style={{...ButtonStyle.primary, marginTop: 25}} onClick={() => setErrorMessages([])}>
                        {i18n.t('button.cancel')}
                    </Button>
                </div>
            </CustomModal>
        </div>
    )
})

type EditTherapyMedicationProps = {
    med?: TherapyPrescriptionDto
    onCancel: () => void
}

export default EditTherapyMedication
