import React from 'react'
import {PatientTherapyRowDto} from '../../../utils/types'
import {AvatarImage} from '../../common'
import {Color} from '../../../style/custom/colors'
import {i18n} from '../../../i18n'
import {Typography} from 'antd'
import {Format} from '../../../utils/constants'
import {format} from 'date-fns'
import {styles} from '../styles/TableHeader.style'

/**
 * The component used for displaying the general patient informations.
 * Will be used in patient table for rendering the content for name column.
 */
const PatientDetailsCell: React.FC<PatientDetailsCellProps> = (props: PatientDetailsCellProps) => {
    return (
        <div style={{display: 'flex', alignItems: 'row'}}>
            <div style={{display: 'flex', alignItems: 'center'}}>
                <AvatarImage />
            </div>
            <div style={{display: 'flex', flexDirection: 'column', flex: 1}}>
                <Typography.Title level={4} style={{color: Color.active, cursor: 'pointer'}}>
                    {props.patient.patientName}
                </Typography.Title>
                <p>
                    {i18n.t('patients.table.birthday', {
                        date: props.patient.birthday ? format(props.patient.birthday, Format.DateFormat) : '-'
                    })}
                </p>
                <p>
                    {i18n.t('patients.table.insuranceNo', {
                        insNo: props.patient.insuranceNo || '-'
                    })}
                </p>
                {!!props.patient.warningMessage && (
                    <div style={styles.warningMessageWrapper}>
                        <p style={styles.warningMessage}>{props.patient.warningMessage}</p>
                    </div>
                )}
            </div>
        </div>
    )
}

type PatientDetailsCellProps = {
    patient: PatientTherapyRowDto
}

export default PatientDetailsCell
