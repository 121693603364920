import React from 'react'
import {styles} from '../patient/styles/PatientPage.style'
import {styles as aboutStyles} from './styles/AboutUs.styles'
import {i18n} from '../../i18n'
import {CEImage, HerstellerBlack, HerstellerWhite, IImage, SNImage, MDImage} from '../../assets'
import {Image} from 'antd'
import {format} from 'date-fns'
import appConfig from '../../custom/config.json'

/**
 * The screen displays general information about the product provider as well as information regarding the current running version of the application
 * In this page the patient is also provided a means of contact.
 */
const AboutUs: React.FC = () => {
    return (
        <div style={styles.pageWrapper}>
            <h3 style={styles.padding}>{i18n.t('about.title')}</h3>
            <p>{i18n.t('about.paragraph1')}</p>
            <p>{i18n.t('about.paragraph2')}</p>
            <p style={{paddingLeft: '3%'}}>
                <span style={aboutStyles.subParagraph}>{'1.'}</span>
                {i18n.t('about.paragraph3.subParagraph1')}
            </p>
            <p style={{paddingLeft: '3%'}}>
                <span style={aboutStyles.subParagraph}>{'2.'}</span>
                {i18n.t('about.paragraph3.subParagraph2')}
            </p>
            <p style={{paddingLeft: '3%'}}>
                <span style={aboutStyles.subParagraph}>{'3.'}</span>
                {i18n.t('about.paragraph3.subParagraph3')}
            </p>

            <table>
                {appConfig.medicalDevice && (
                <tr style={aboutStyles.row}>
                    <td style={aboutStyles.column1}>
                        <Image src={CEImage} preview={false} style={aboutStyles.image} />
                    </td>
                </tr>
                )}

                {appConfig.medicalDevice && (
               <tr style={aboutStyles.row}>
                    <td style={aboutStyles.column1}>
                        <Image src={MDImage} preview={false} style={aboutStyles.image} />
                    </td>
                </tr>
                )}

                <tr style={aboutStyles.row}>
                    <td style={aboutStyles.column1}>
                        <Image src={IImage} preview={false} style={aboutStyles.image} />
                    </td>
                </tr>

                <tr style={aboutStyles.row}>
                    <td style={aboutStyles.column1}>
                        <Image src={SNImage} preview={false} style={aboutStyles.image} />
                    </td>
                    <td style={aboutStyles.column2}>
                        <div style={aboutStyles.column2Content}>
                            <span>{i18n.t('about.table.version', {version: process.env.REACT_APP_VERSION || '-'})}</span>
                            <p>{i18n.t('about.table.createdVersion', {gitRevision: process.env.REACT_APP_GIT_REVISION_NUMBER || '-'})}</p>
                        </div>
                    </td>
                </tr>

                {/*{appConfig.medicalDevice && (*/}
                {/*<tr style={aboutStyles.row}>*/}
                {/*    <td style={aboutStyles.column1}>*/}
                {/*        <Image src={UDIImage} preview={false} style={aboutStyles.image} />*/}
                {/*    </td>*/}
                {/*    <td style={aboutStyles.column2}>*/}
                {/*        <div style={aboutStyles.column2Content}>*/}
                {/*            <p>*/}
                {/*                {i18n.t('about.table.udi', {*/}
                {/*                    date: process.env.REACT_APP_DATE_TIME*/}
                {/*                        ? format(new Date(Date.parse(process.env.REACT_APP_DATE_TIME)), 'yyyyMMdd')*/}
                {/*                        : '-',*/}
                {/*                    version: process.env.REACT_APP_VERSION || '-'*/}
                {/*                })}*/}
                {/*            </p>*/}
                {/*        </div>*/}
                {/*    </td>*/}
                {/*</tr>*/}
                {/*)}*/}

                <tr style={aboutStyles.row}>
                    <td style={aboutStyles.column1}>
                        <Image src={HerstellerWhite} preview={false} style={aboutStyles.image} />
                    </td>
                    <td style={aboutStyles.column2}>
                        <div style={aboutStyles.column2Content}>
                            <p>
                                {i18n.t('about.table.date', {
                                    date: process.env.REACT_APP_DATE_TIME
                                        ? format(new Date(Date.parse(process.env.REACT_APP_DATE_TIME)), 'ddMMyyyy')
                                        : '-'
                                })}
                            </p>
                        </div>
                    </td>
                </tr>

                <tr style={aboutStyles.row}>
                    <td style={aboutStyles.column1}>
                        <Image src={HerstellerBlack} preview={false} style={aboutStyles.image} />
                    </td>
                    <td style={aboutStyles.column2}>
                        <div style={aboutStyles.column2Content}>
                            <span>{i18n.t('about.table.info.name')}</span>
                            <span>{i18n.t('about.table.info.address')}</span>
                            <span>{i18n.t('about.table.info.contact')}</span>
                            <p />
                            {appConfig.showWebsite && (
                            <p>
                                <a onClick={() => window.location.replace('https://' + i18n.t('about.table.info.website'))} target='_blank'>
                                    {i18n.t('about.table.info.website')}
                                </a>
                            </p>
                            )}
                            <p>
                                <a href={`mailto: ${i18n.t('about.table.info.email')}`}>{i18n.t('about.table.info.email')}</a>
                            </p>

                            <span>{i18n.t('about.table.info.contactPerson')}</span>
                            <p>{i18n.t('about.table.info.code')}</p>
                        </div>
                    </td>
                </tr>
            </table>
        </div>
    )
}

export default AboutUs
