import React, {CSSProperties} from 'react'
import {styles} from '../../styles/Box.style'
import {Color} from '../../../../style/custom/colors'
import {getBoxColor} from '../../../../utils/medication_helper'

/**
 * Component representing a single box in the BoxDiagram component.
 */
const Box: React.FC<BoxProps> = props => {
    const {squareColor, textColor} = getBoxColor(props.isIntake, props.isExcluded, props.isCurrentDay)
    const boxStyles = {backgroundColor: squareColor, ...(props.isExcluded ? styles.excludedBox : {})}
    return (
        <div style={{...styles.box, ...boxStyles}} onClick={props.onClick}>
            <span style={{...styles.boxText, color: props.displayTextCondition ? textColor : Color.transparent}}>
                {props.displayTextCondition ? props.displayText : '.'}
            </span>
        </div>
    )
}

type BoxProps = {
    displayText: string,
    displayTextCondition?: boolean,
    isIntake: boolean,
    isExcluded: boolean,
    isCurrentDay: boolean,
    onClick?: () => void
    styles?: CSSProperties
}

export default Box
