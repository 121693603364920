import React from 'react'
import {Dropdown, Menu} from 'antd'
import {CaretDownFilled} from '@ant-design/icons'
import {i18n} from '../../i18n'
import {OptionItem} from '../../utils/types'
import {Color} from '../../style/custom/colors'
import {TitleText} from './index'

/**
 * Component used for rendering a custom drop-down select.
 * Also will render the current selected value from drop-down.
 */
const CustomDropdown: React.FC<DropdownProps> = (props: DropdownProps) => {
    const menu = (
        <Menu>
            {props.items.map(item => (
                <Menu.Item key={item.key} onClick={() => props.onItemSelect?.(item)}>
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            marginTop: '2%',
                            marginBottom: '2%'
                        }}>
                        {item.label}
                    </div>
                </Menu.Item>
            ))}
        </Menu>
    )
    const selected =
        typeof props.selectedItem?.label === 'string' ? (
            <TitleText text={props.selectedItem?.label || i18n.t('common.dropdown.noValue')} size={26} />
        ) : (
            props.selectedItem?.label
        )
    return (
        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
            {selected}
            <div style={{display: 'flex', alignItems: 'center'}}>
                <Dropdown overlay={menu} trigger={['click']} arrow={true}>
                    <CaretDownFilled style={{fontSize: '20px', color: Color.neutral1}} />
                </Dropdown>
            </div>
        </div>
    )
}

type DropdownProps = {
    items: OptionItem[]
    selectedItem?: OptionItem
    onItemSelect?: (selected: OptionItem) => void
}

export default CustomDropdown
